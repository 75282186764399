import { eventFactory } from '../utils/monitoring/event-factory';
import { sendEvent } from '../utils/monitoring/send-bi';

const SERVICE_WORKER_PATH = '/verify/app/assets/service-worker.js';
const SERVICE_WORKER_SCOPE = '/verify/app/';

export async function registerServiceWorker(startToken: string, clientId: string, debug: boolean) {
  if ('serviceWorker' in navigator) {
    if (debug) console.log('Service Worker support detected');

    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          `${SERVICE_WORKER_PATH}?debug=${debug === true}&startToken=${startToken}`,
          {
            scope: SERVICE_WORKER_SCOPE,
          },
        );
        if (debug) console.log('ServiceWorker registration successful:', registration.scope);
      } catch (error) {
        const eventReport = eventFactory.createErrorEvent(
          'ServiceWorker registration failed',
          error?.toString() ?? 'Unknown error',
          'init',
        );
        sendEvent(startToken, eventReport);
        console.error('ServiceWorker registration failed:', error);
      }
    });

    navigator.serviceWorker.ready.then((registration) => {
      if (debug) console.log('sending message to service worker - app is loaded');

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type === 'MODEL_LOAD_ERROR') {
          const eventReport = eventFactory.createErrorEvent(
            'Failed to load model',
            JSON.stringify(event.data.error),
            'init',
          );
          sendEvent(startToken, eventReport);
          console.error('Failed to load model:', event.data.modelName, event.data.error);
        }
      });

      registration.active?.postMessage({
        type: 'APP_LOADED',
        clientId,
        debug,
      });
    });
  } else {
    console.warn('Service Worker support not detected');
  }
}
